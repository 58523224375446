<template>
  <KSnackbar
    :color="color"
    location="top"
    :model-value="shown"
    :timeout="3000"
    @update:model-value="onChange"
  >
    <KIcon v-if="icon" color="white" :icon="icon" start />
    {{ message }}
  </KSnackbar>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useCommonUiStore } from '~/stores/commonUi'

const commonUiStore = useCommonUiStore()
const shown = computed(() => commonUiStore.snackbar.shown)
const color = computed(() => commonUiStore.snackbar.color)
const message = computed(() => commonUiStore.snackbar.message)
const icon = computed(() => {
  switch (color.value) {
    case 'success':
      return '$mdiCheckCircle'
    case 'error':
      return '$mdiAlert'
    default:
      return null
  }
})

const onChange = (shown: boolean) => {
  if (!shown) {
    commonUiStore.hideSnackbar()
  }
}
</script>

<style scoped></style>
